<template>
  <div :id="this.gaugeId" class="vue-gauge-item"></div>
</template>
<script>
const GaugeChart = require('gauge-chart/dist/bundle.js');
export default {
  name: 'vue-gauge',
  props:['refid','options'],
  mounted() {
    this.initPlugin(this.options);
  },
  methods: {
    initPlugin(options = {}){
      if(this.gaugeId){
        let config = {
          hasNeedle: true,
          needleColor: '#000',
          needleUpdateSpeed: 1000,
          arcColors: ['#85a3ca', '#d3d3d3'],
          arcDelimiters: [50],
          rangeLabel: ['0', '100'],
          chartWidth: 250,
          needleValue: 0
        };
        config = { ...config, ...options };
        // Element inside which you want to see the chart
        let element = document.querySelector("#"+this.gaugeId);

        // Drawing and updating the chart
        this.gauge = GaugeChart.gaugeChart(element, config.chartWidth, config);
        this.gauge.updateNeedle(config.needleValue);
      }
    },
    reloadPlugin() {
      if (this.gauge) {
        this.gauge.removeGauge();
      }
      this.initPlugin(this.options);
    }
  },
  computed: {
    gaugeId(){
      if(typeof this.refid != 'undefined'){
        return this.refid;
      }
      return "vue-gauge";
    }
  },
}
</script>
