<template>
  <v-container fluid>
    <h1>{{ $vuetify.lang.t('$vuetify.dashboard.willkommen', mieter.vorname, mieter.nachname) }}</h1>
    <p>
      {{vertrag.wohneinheit.geschosslage}}, {{vertrag.wohneinheit.gebaeude.ort}}, {{vertrag.wohneinheit.gebaeude.strasse}} {{vertrag.wohneinheit.gebaeude.hausnr}}
    </p>
    <p>
      {{$vuetify.lang.t('$vuetify.dashboard.beschreibung')}}
    </p>
    <v-alert type="info" v-if="contractExpired">
      {{$vuetify.lang.t('$vuetify.dashboard.vertrag_ausgelaufen', contractExpired)}}
    </v-alert>
    <br/>
    <v-row>
      <v-col md="6" cols="12" v-if="vertrag.wohneinheit.heizenergie">
        <v-card color="accent" class="d-flex flex-column counter-card fire-bg" height="240" :loading="loadingHeizenergie" dark>
          <template slot="progress">
            <v-overlay absolute>
              <v-progress-circular
                  indeterminate
                  size="32"
              ></v-progress-circular>
            </v-overlay>
          </template>
          <v-card-text class="text-center">
            <h1>{{ $vuetify.lang.t('$vuetify.dashboard.heizenergie_letzter_monat', heizenergieMonat) }}</h1>
            <h2>{{ heizenergieverbrauch }}</h2>
            <small>{{ $vuetify.lang.t('$vuetify.dashboard.'+heizenergieEinheit) }}</small>
          </v-card-text>
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-btn color="white" light depressed block link to="/heizenergie">
              {{ $vuetify.lang.t('$vuetify.dashboard.zum_bericht') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col md="6" cols="12" v-if="vertrag.wohneinheit.warmwasser">
        <v-card color="primary" height="240" class="d-flex flex-column counter-card water-bg" :loading="loadingWarmwasser" dark>
          <template slot="progress">
            <v-overlay absolute>
              <v-progress-circular
                  indeterminate
                  size="32"
              ></v-progress-circular>
            </v-overlay>
          </template>
          <v-card-text class="text-center">
            <h1>{{ $vuetify.lang.t('$vuetify.dashboard.warmwasser_letzter_monat', warmwasserMonat) }}</h1>
            <h2>{{ warmwasserverbrauch }}</h2>
            <small>{{ $vuetify.lang.t('$vuetify.dashboard.kwh') }}</small>
          </v-card-text>
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-btn color="white" light depressed block link to="/warmwasser">
              {{ $vuetify.lang.t('$vuetify.dashboard.zum_bericht') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col xl="5" lg="4" cols="12" v-show="showVergleichVorjahrHeizenergie || showVergleichVorjahrWarmwasser">
        <v-card :loading="loadingHeizenergie" height="100%" class="d-flex flex-column">
          <v-card-title>
            {{$vuetify.lang.t('$vuetify.dashboard.verbrauch_vorjahresvergleich')}}
          </v-card-title>
          <v-card-text>
            <div v-show="vertrag.wohneinheit.heizenergie">
              <div class="pl-1">
                {{ $vuetify.lang.t('$vuetify.dashboard.'+heizenergieEinheit) }}
              </div>
              <BarChartVergleichVorjahr ref="heizenergieChart"/>
              <v-skeleton-loader
                  type="text"
                  v-show="loadingHeizenergie"
              ></v-skeleton-loader>
              <p class="mb-5" v-show="!loadingHeizenergie">
                {{heizenergieVorjahrVergleichText}}
              </p>
            </div>
            <div v-show="vertrag.wohneinheit.warmwasser">
              <div class="pl-1">
                {{ $vuetify.lang.t('$vuetify.dashboard.kwh') }}
              </div>
              <BarChartVergleichVorjahr ref="warmwasserChart"/>
              <v-skeleton-loader
                  type="text"
                  v-show="loadingWarmwasser"
              ></v-skeleton-loader>
              <p v-show="!loadingWarmwasser">
                {{warmwasserVorjahrVergleichText}}
              </p>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col xl="4" lg="4" md="6" cols="12">
        <v-card height="100%" class="d-flex flex-column" href="https://www.bmuv.de/themen/gesundheit-chemikalien/gesundheit/innenraumluft/richtiges-lueften-und-heizen" rel="noopener" target="_blank">
          <v-card-title>
            Sparen leicht gemacht
          </v-card-title>
          <v-card-text>
            Im Winter machen wir es uns drinnen gern gemütlich. Dabei sind Raumtemperatur und Luftfeuchtigkeit entscheidend. Zum richtigen Heizen und Lüften gibt es ein paar einfache Regeln. Wer sich daran hält, spart Energie und kommt gesünder durch den Winter.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" depressed>
              Mehr erfahren
            </v-btn>
          </v-card-actions>
          <v-spacer></v-spacer>
          <v-img src="/assets/images/card-sparen.webp" height="236px" position="right bottom"></v-img>
        </v-card>
      </v-col>
      <v-col xl="3" lg="4" md="6" cols="12" v-show="showVergleichLiegenschaftHeizenergie || showVergleichLiegenschaftWarmwasser">
        <v-card height="100%" class="d-flex flex-column">
          <v-card-text v-show="vertrag.wohneinheit.heizenergie">
            <div v-show="loadingHeizenergie">
              <v-skeleton-loader
                  type="image"
              ></v-skeleton-loader>
              <br/>
              <v-skeleton-loader
                  type="text"
              ></v-skeleton-loader>
            </div>
            <div v-show="!loadingHeizenergie">
              <div class="text-center">
                <gauge-chart ref="heizenergieGauge" refid="gauge-heizenergie" :options="heizenergieGaugeOptions"></gauge-chart>
              </div>
              <p>
                {{heizenergieLiegenschaftVergleichText}}
              </p>
            </div>
          </v-card-text>
          <v-card-text v-show="vertrag.wohneinheit.warmwasser">
            <div v-show="loadingWarmwasser">
              <v-skeleton-loader
                  type="image"
              ></v-skeleton-loader>
              <br/>
              <v-skeleton-loader
                  type="text"
              ></v-skeleton-loader>
            </div>
            <div v-show="!loadingWarmwasser">
              <div class="text-center">
                <gauge-chart ref="warmwasserGauge" refid="gauge-warmwasser" :options="warmwasserGaugeOptions"></gauge-chart>
              </div>
              <p>
                {{warmwasserLiegenschaftVergleichText}}
              </p>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import client from '@/utils/client';
import GaugeChart from '@/components/GaugeChart';
import BarChartVergleichVorjahr from '@/components/BarChartVergleichVorjahr';
import Color from "color";
import moment from 'moment';

export default {
  components: { BarChartVergleichVorjahr, GaugeChart, },
  data() {
    return {
      loadingHeizenergie: true,
      loadingWarmwasser: false,
      heizenergieverbrauch: this.$vuetify.lang.t('$vuetify.dashboard.na'),
      heizenergieMonat: this.$vuetify.lang.t('$vuetify.dashboard.letzter_monat'),
      warmwasserverbrauch: this.$vuetify.lang.t('$vuetify.dashboard.na'),
      warmwasserMonat: this.$vuetify.lang.t('$vuetify.dashboard.letzter_monat'),
      heizenergieVorjahrVergleichText: '',
      warmwasserVorjahrVergleichText: '',
      heizenergieGaugeOptions: {
        needleColor: '#000',
        arcDelimiters: [50],
        needleValue: 0,
        chartWidth: 264,
        arcColors: [this.$vuetify.theme.currentTheme.accent, '#b90f5f'],
        rangeLabel: [],
        centralLabel: '0%',
        arcOverEffect: false,
        outerNeedle: true,
      },
      warmwasserGaugeOptions: {
        needleColor: '#000',
        arcDelimiters: [50],
        needleValue: 0,
        chartWidth: 264,
        arcColors: [this.$vuetify.theme.currentTheme.primary, '#b90f5f'],
        rangeLabel: [],
        centralLabel: '0%',
        arcOverEffect: false,
        outerNeedle: true,
      },
      heizenergieLiegenschaftVergleichText: '',
      warmwasserLiegenschaftVergleichText: '',
      showVergleichVorjahrHeizenergie: true,
      showVergleichVorjahrWarmwasser: true,
      showVergleichLiegenschaftHeizenergie: true,
      showVergleichLiegenschaftWarmwasser: true,
    };
  },
  computed: mapState({
    mieter: state => state.user,
    vertrag: state => state.vertrag,
    contractExpired: function() {
      if(this.vertrag.ende && new Date() > new Date(this.vertrag.ende)) {
        return moment(this.vertrag.ende).format('L');
      }
      return false;
    },
    heizenergieEinheit: function() {
      return this.vertrag.wohneinheit.heizenergie_einheit === 'mixed' || !this.vertrag.wohneinheit.heizenergie_einheit  ? 'kwh' : this.vertrag.wohneinheit.heizenergie_einheit;
    }
  }),
  mounted() {
    this.loadData();
  },
  watch: {
    '$store.state.vertrag.id': {
      handler() {
        this.loadData();
      }
    }
  },
  methods: {
    loadData() {
      this.loadingHeizenergie = true;
      client.get('/vertraege/' + this.vertrag.id + '/messwerte/heizenergie', {limit:3}).then(response => {
        let lastMonthData = response.data.length ? response.data[0] : {};
        let vergleichLiegenschaft = false;
        if(Object.keys(lastMonthData).length) {
          let current = this.heizenergieEinheit === 'kwh' ? lastMonthData.kwh : lastMonthData.verbrauchswert;
          this.heizenergieverbrauch = (new Intl.NumberFormat()).format(current);
          this.heizenergieMonat = this.$vuetify.lang.t('$vuetify.datum.monat_' + lastMonthData.monat);
          if(typeof lastMonthData.verbrauchswert_vorjahr !== 'undefined') {
            let last = this.heizenergieEinheit === 'kwh' ? lastMonthData.kwh_vorjahr : lastMonthData.verbrauchswert_vorjahr;
            let percent = Math.round((last - current) / last * -100);
            if (percent > 0) {
              this.heizenergieVorjahrVergleichText = this.$vuetify.lang.t('$vuetify.dashboard.heizenergie_vorjahr_vergleich_text_mehr', this.$vuetify.lang.t('$vuetify.datum.monat_' + lastMonthData.monat), Math.abs(percent));
            } else {
              this.heizenergieVorjahrVergleichText = this.$vuetify.lang.t('$vuetify.dashboard.heizenergie_vorjahr_vergleich_text_weniger', this.$vuetify.lang.t('$vuetify.datum.monat_' + lastMonthData.monat), Math.abs(percent));
            }
            this.showVergleichVorjahrHeizenergie = true;
          } else {
            this.showVergleichVorjahrHeizenergie = false;
            this.heizenergieVorjahrVergleichText = '';
          }
          if(typeof lastMonthData.verbrauchswert_liegenschaft_qm_kwh !== 'undefined' && lastMonthData.verbrauchswert_liegenschaft_qm_kwh !== null) {
            let percent = lastMonthData.differenz_liegenschaft_qm_prozent;
            this.heizenergieGaugeOptions.arcColors = [this.$vuetify.theme.currentTheme.accent, '#b90f5f'];
            this.heizenergieGaugeOptions.needleValue = Math.round(50 - percent / 2 * -1);
            this.heizenergieGaugeOptions.centralLabel = Math.round(percent) + '%';
            if(percent > 0) {
              this.heizenergieLiegenschaftVergleichText = this.$vuetify.lang.t('$vuetify.dashboard.heizenergie_liegenschaft_vergleich_text_oberhalb', this.$vuetify.lang.t('$vuetify.datum.monat_' + lastMonthData.monat), Math.abs(Math.round(percent)));
            } else {
              this.heizenergieLiegenschaftVergleichText = this.$vuetify.lang.t('$vuetify.dashboard.heizenergie_liegenschaft_vergleich_text_unterhalb', this.$vuetify.lang.t('$vuetify.datum.monat_' + lastMonthData.monat), Math.abs(Math.round(percent)));
            }
            vergleichLiegenschaft = true;
          }
        } else {
          this.showVergleichVorjahrHeizenergie = false;
          this.heizenergieVorjahrVergleichText = '';
          this.heizenergieverbrauch = this.$vuetify.lang.t('$vuetify.dashboard.na');
        }
        this.loadingHeizenergie = false;
        let dataCurrentYear = [];
        let dataLastYear = [];
        let labels = [];
        response.data.forEach(monthData => {
          dataCurrentYear.push(this.heizenergieEinheit === 'kwh' ? monthData.kwh : monthData.verbrauchswert);
          let verbrauchswertVorjahr = 0;
          if(typeof monthData.verbrauchswert_vorjahr !== 'undefined') {
            verbrauchswertVorjahr = this.heizenergieEinheit === 'kwh' ? monthData.kwh_vorjahr : monthData.verbrauchswert_vorjahr;
          }
          dataLastYear.push(verbrauchswertVorjahr);
          labels.push(this.$vuetify.lang.t('$vuetify.datum.monat_' + monthData.monat));
        });
        let accentColor = new Color(this.$vuetify.theme.currentTheme.accent);
        let data = {
          labels,
          datasets: [
            {
              label: this.$vuetify.lang.t('$vuetify.dashboard.aktuelles_jahr'),
              data: dataCurrentYear,
              borderColor: this.$vuetify.theme.currentTheme.accent,
              backgroundColor: this.$vuetify.theme.currentTheme.accent,
              borderRadius: 5,
              maxBarThickness: 40,
            },
            {
              label: this.$vuetify.lang.t('$vuetify.dashboard.vorjahr'),
              data: dataLastYear,
              borderColor: accentColor.lighten(0.35).hex(),
              backgroundColor: accentColor.lighten(0.35).hex(),
              borderRadius: 5,
              maxBarThickness: 40,
            }
          ]
        };
        this.$refs.heizenergieChart.loadChartData(data);
        if(vergleichLiegenschaft) {
          this.showVergleichLiegenschaftHeizenergie = true;
          this.$refs.heizenergieGauge.reloadPlugin();
        } else {
          this.showVergleichLiegenschaftHeizenergie = false;
        }
      }).catch(() => {
        this.loadingHeizenergie = false;
      });
      this.loadingWarmwasser = true;
      client.get('/vertraege/' + this.vertrag.id + '/messwerte/warmwasser', {limit:3}).then(response => {
        let lastMonthData = response.data.length ? response.data[0] : {};
        let vergleichLiegenschaft = false;
        if(Object.keys(lastMonthData).length) {
          let current = lastMonthData.kwh;
          this.warmwasserverbrauch = (new Intl.NumberFormat()).format(current);
          this.warmwasserMonat = this.$vuetify.lang.t('$vuetify.datum.monat_' + lastMonthData.monat);
          if(typeof lastMonthData.kwh_vorjahr !== 'undefined') {
            let last = lastMonthData.kwh_vorjahr;
            let percent = Math.round((last - current) / last * -100);
            if (percent > 0) {
              this.warmwasserVorjahrVergleichText = this.$vuetify.lang.t('$vuetify.dashboard.warmwasser_vorjahr_vergleich_text_mehr', this.$vuetify.lang.t('$vuetify.datum.monat_' + lastMonthData.monat), Math.abs(percent));
            } else {
              this.warmwasserVorjahrVergleichText = this.$vuetify.lang.t('$vuetify.dashboard.warmwasser_vorjahr_vergleich_text_weniger', this.$vuetify.lang.t('$vuetify.datum.monat_' + lastMonthData.monat), Math.abs(percent));
            }
            this.showVergleichVorjahrWarmwasser = true;
          } else {
            this.showVergleichVorjahrWarmwasser = false;
            this.warmwasserVorjahrVergleichText = '';
          }
          if(typeof lastMonthData.verbrauchswert_liegenschaft_qm_kwh !== 'undefined' && lastMonthData.verbrauchswert_liegenschaft_qm_kwh !== null) {
            let percent = lastMonthData.differenz_liegenschaft_qm_prozent;
            this.warmwasserGaugeOptions.arcColors = [this.$vuetify.theme.currentTheme.primary, '#b90f5f'];
            this.warmwasserGaugeOptions.needleValue = Math.round(50 - percent / 2 * -1);
            this.warmwasserGaugeOptions.centralLabel = Math.round(percent) + '%';
            if(percent > 0) {
              this.warmwasserLiegenschaftVergleichText = this.$vuetify.lang.t('$vuetify.dashboard.warmwasser_liegenschaft_vergleich_text_oberhalb', this.$vuetify.lang.t('$vuetify.datum.monat_' + lastMonthData.monat), Math.abs(Math.round(percent)));
            } else {
              this.warmwasserLiegenschaftVergleichText = this.$vuetify.lang.t('$vuetify.dashboard.warmwasser_liegenschaft_vergleich_text_unterhalb', this.$vuetify.lang.t('$vuetify.datum.monat_' + lastMonthData.monat), Math.abs(Math.round(percent)));
            }
            vergleichLiegenschaft = true;
          }
        } else {
          this.warmwasserVorjahrVergleichText = '';
          this.warmwasserverbrauch = this.$vuetify.lang.t('$vuetify.dashboard.na');
          this.showVergleichVorjahrWarmwasser = false;
        }
        this.loadingWarmwasser = false;
        let dataCurrentYear = [];
        let dataLastYear = [];
        let labels = [];
        response.data.forEach(monthData => {
          dataCurrentYear.push(monthData.kwh);
          dataLastYear.push(typeof monthData.kwh_vorjahr === 'undefined' ? 0 : monthData.kwh_vorjahr); // TODO: kwh
          labels.push(this.$vuetify.lang.t('$vuetify.datum.monat_' + monthData.monat));
        });
        let primaryColor = new Color(this.$vuetify.theme.currentTheme.primary);
        let data = {
          labels,
          datasets: [
            {
              label: this.$vuetify.lang.t('$vuetify.dashboard.aktuelles_jahr'),
              data: dataCurrentYear,
              borderColor: this.$vuetify.theme.currentTheme.primary,
              backgroundColor: this.$vuetify.theme.currentTheme.primary,
              borderRadius: 5,
              maxBarThickness: 40,
            },
            {
              label: this.$vuetify.lang.t('$vuetify.dashboard.vorjahr'),
              data: dataLastYear,
              borderColor: primaryColor.lighten(0.35).hex(),
              backgroundColor: primaryColor.lighten(0.35).hex(),
              borderRadius: 5,
              maxBarThickness: 40,
            }
          ]
        };
        this.$refs.warmwasserChart.loadChartData(data);
        if(vergleichLiegenschaft) {
          this.showVergleichLiegenschaftWarmwasser = true;
          this.$refs.warmwasserGauge.reloadPlugin();
        } else {
          this.showVergleichLiegenschaftWarmwasser = false;
        }
      }).catch(() => {
        this.loadingWarmwasser = false;
      });
    }
  }
}
</script>

<style lang="sass">
.counter-card
  h1
    font-size: 20px
    line-height: 1
    text-transform: none
    margin-bottom: 20px
    font-weight: 300
  h2
    font-size: 60px
    line-height: 72px
    margin: 20px 0 0
    font-weight: 500
  small
    font-size: 16px
    line-height: 1
  .v-btn
    font-weight: 300
    font-size: 16px
.fire-bg, .water-bg
  background-image: url(/assets/images/card-fire.svg)
  background-repeat: no-repeat
  background-size: 121px 140px
  background-position: 16px 16px
.water-bg
  background-image: url(/assets/images/card-water.svg)
  background-size: 99px 128px
.vue-gauge-item svg
  height: 130px
</style>
